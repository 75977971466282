import React from "react"
import Contact from "../components/Contact/Contact"
import Footer from "../components/Footer/Footer"
//import ContentV2 from "../components/ContentV/ContentV2/ContentV2"
//import ContentV6 from "../components/ContentV/ContentV6/ContentV6"
import Navbar from "../components/Navbar/Navbar"
//import Header from "../components/Headers/Header";

const contactPage = () => {
  return (
    <div>
      <Navbar />
      <article className="w-[100%] min-h-screen">
        <Contact />
      </article>
      <Footer />
    </div>
  )
}

export default contactPage
