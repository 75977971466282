import React, { useRef, useState } from "react"
import emailjs from "@emailjs/browser"

import contactImage from "../../images/contactImage.png"
import useMediaQuery from "../../hooks/useMediaQuery"

const Contact = () => {
  const form = useRef<HTMLFormElement>(null)

  const [result, showResult] = useState(false)

  const sendEmail = e => {
    e.preventDefault()

    if (!form.current) return

    emailjs
      .sendForm(
        "service_ow0yzdq",
        "template_t3uud3i",
        form.current,
        "9sVOYX0vzUl0KnRKl"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
    showResult(true)
  }

  const isDesktop = useMediaQuery("(min-width: 1400px)")
  return (
    <div className="flex items-center justify-center lg:mt-0 mt-24">
      <div className="flex lg:w-[90%] items-center justify-center h-screen flex-row">
        <div className="flex flex-col h-auto w-[100%] items-center">
          <div className="lg:w-[60%] lg:h-10 w-[100%] flex lg:justify-start lg:items-center justify-center">
            <h2 className="text-5xl text-white font-bold">Contact</h2>
          </div>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex flex-col text-white mt-10 lg:h-96 h-2/6 lg:w-[60%] w-[90%] justify-around"
          >
            <input
              type="text"
              placeholder="Name"
              name="from_name"
              className="bg-transparent border-b focus:border-white outline-none py-3 border-0 mb-3 !ring-0"
            />
            <div className="flex justify-between lg:flex-row flex-col">
              <input
                type="text"
                placeholder="Phone"
                name="phone"
                className="bg-transparent border-b outline-none focus:border-white lg:w-[45%] py-3 border-0 mb-3 !ring-0"
              />
              <input
                type="text"
                placeholder="E-mail"
                name="email"
                className="bg-transparent border-b outline-none focus:border-white lg:w-[45%] py-3 border-0 mb-3 !ring-0"
              />
            </div>
            <div className="relative">
              <textarea
                id=""
                rows={2}
                placeholder="Message"
                name="message"
                className="peer bg-transparent border-b border-0 focus:border-white !outline-none leading-loose !ring-0 w-full"
              >
                lolz
              </textarea>
              <span className="peer-focus:border-white absolute left-0 right-0 h-px border-b border-[#6b7280] top-[2.7rem] z-10" />
            </div>
            {/* <span className="relative h-[1px] border-b top-[-2.5rem] z-10 w-full bg-white" /> */}

            <div className="flex flex-row items-center mt-10 pb-2">
              <input
                type="checkbox"
                id="first_opt"
                name="option1"
                value="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam"
                className="lg:w-5 lg:h-5 w-8 h-8 mr-2 bg-transparent !ring-0"
              />
              <label htmlFor="first_opt">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam
              </label>
            </div>
            <div className="flex flex-row items-center">
              <input
                type="checkbox"
                id="second_opt"
                name="option2"
                value="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam"
                className="lg:w-5 lg:h-5 w-8 h-8 mr-2 bg-transparent !ring-0"
              />
              <label htmlFor="second_opt">
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam
              </label>
            </div>
            <button type="submit" className="mt-10 border-2 p-2">
              Send Message
            </button>
          </form>
          <div>
            {result ? (
              <div className="text-slate-300 text-2xl bg-green-300 bg-opacity-50 p-2 px-4 border-2 mt-5 rounded">
                <p>E-mail sent</p>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        {isDesktop ? (
          <div className="w-[60%] aspect-[5/4] rounded-tr-[10%] rounded-bl-[10%]">
            <img src={contactImage} />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  )
}

export default Contact
